import axios from 'axios';
import config from "../lib/config.js";
import { Decryptdata, Encryptdata } from '../lib/common.js';


export const loginAdmin = async (payload) => {
    try {
        const resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/adminlogin`,
            'data': { data: Encryptdata(payload) }

        })
        let response = Decryptdata(resp.data)

        if (response)
            localStorage.setItem("token", response.token);

        return response;
    }
    catch (err) {
        console.log("err", err)
    }


}

//roadmap
export const addroadmap = async (data) => {
    try {
        let resp = await axios({

            'method': 'post',
            'url': `${config.AdminAPI}/addroadmap`,
            'data': { data: Encryptdata(data) },
            "headers": {
                'Content-Type': 'application/json',

            },
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch (err) { console.log("err in gettok owner", err) }
}

export const editroadmap = async (data) => {
    try {
        let resp = await axios({

            'method': 'post',
            'url': `${config.AdminAPI}/editroadmap`,
            'data': { data: Encryptdata(data) },
            "headers": {
                'Content-Type': 'application/json',

            },
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch (err) { console.log("err in gettok owner", err) }
}

export const roadmaplist = async () => {
    console.log("addroadmapaddroadmap");
    try {
        let resp = await axios({

            'method': 'get',
            'url': `${config.AdminAPI}/roadmaplist`,
            "headers": {
                'Content-Type': 'application/json',

            },


        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch (err) { console.log("err in gettok owner", err) }
}


export const getsettinglist = async () => {
    try {
        let resp = await axios({
            'method': 'get',
            'url': `${config.AdminAPI}/getsettings`,
            "headers": {
                'Content-Type': 'application/json',
            },
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch (err) {
        console.log("🚀 ~ getsettinglist ~ err:", err)
        return {}
    }
}

export const editSetting = async (data) => {
    try {
        let resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/settingupdate`,
            data: { data: Encryptdata(data) },
            "headers": {
                'Content-Type': 'application/json',
            },
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch (err) {
        console.log("🚀 ~ getsettinglist ~ err:", err)
        return {}
    }
}