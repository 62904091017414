import { lazy } from "react";



const Routes = [
    {
        path: "/cmsandfaq",
        component: lazy(() => import('../cmsAndFaq/cmsbox.js')),
        name: '/admin'
    },
    {
        path: "/",
        component: lazy(() => import('../loginpages/Login.js')),
        name: '/'
    },
    {
        path: "/login/admin",
        component: lazy(() => import('../loginpages/Login.js')),
    
    },
    {
        path: "/dashboard",
        component: lazy(() => import('../dashboard/Dashboard.js')),
        name: '/admin'
    },
    {
        path: "/currencylist",
        component: lazy(() => import('../currencyList/currencylist.js')),
        name: '/admin'
    },
    {
        path: "/addtoken",
        component: lazy(() => import('../currencyList/addtoken.js')),
        name: '/admin'
    },
    {
        path: "/editcms",
        component: lazy(() => import('../cmscontent/cmslist.js')),
        name: '/admin'
    },
    {
        path: "/cmslist",
        component: lazy(() => import('../cmscontent/cmslist.js')),
        name: '/admin'
    },
    {
        path: "/editcms/:data",
        component: lazy(() => import('../cmsAndFaq/editcms.js')),
        name: '/admin'
    },
    {
        path: "/sociallist",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/editsociallink",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/userlist",
        component: lazy(() => import('../user/userlist')),
        name: '/admin'
    },
    {
        path:"/userdetail",
        component: lazy(() => import('../user/userlist')),
        name:'/admin'
    },
    {
        path:"/tokenlist",
        component: lazy(() => import('../token/tokenlist')),
        name:'/admin'
    },
    {
        path:"/viewdetail",
        component: lazy(() => import('../token/tokenlist')),
        name:'/admin'
    },
    {
        path: "/faqlist",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/addfaq",
        component: lazy(() => import('../faqlist/addfaq.js')),
        name: '/admin'
    },
    {
        path: "/editfaq",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },

       //Roadmap
   {
    path: "/addroadmap",
    component: lazy(() => import('../Roadmap/addroadmap.js')),
    name: '/admin'
   },
   {
       path: "/editroadmap",
       component: lazy(() => import('../Roadmap/editroadmap.js')),
       name: '/admin'
      },
      {
       path: "/roadmaplist",
       component: lazy(() => import('../Roadmap/roadmaplist.js')),
       name: '/admin'
      },
      {
        path: "/setting",
        component: lazy(() => import('../settings/settinglist.js')),
        name: '/admin'
       },
]


export default Routes;
