import Config from "./config";
import CryptoJS from "crypto-js";

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const NumANdDotOnly = (data) => {
  var data = data.toString();
  var str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        : data
      : data
    : data;
  return str.toString().replace(Config.NumDigitOnly, "");
};

export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly, "");
};

export const Decryptdata = (data,key) => {
  try{
  if(isEmpty(data)){
    return data
  }
  if(key){
   return CryptoJS.AES.decrypt(data, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8)
  }
  else{
    const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
    const bytes = CryptoJS.AES.decrypt(decData, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8);

    return JSON.parse(bytes)
  }
  
  
  }
  catch(e){
      console.log("Decryptdata_err",e);
    return data
  }
}

export const Encryptdata = (data,key) => {
  try{
    if(key){
      return CryptoJS.AES.encrypt(data,Config.SECRET_KEY).toString()
    }
    else{
      const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), Config.SECRET_KEY).toString();
      const encData = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(encJson)
      );
      return encData;
    }
  }catch(e){
    console.log('Encryptdata_err-->',e)
    return ""
  }
 
  
}


export const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map((data) => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], Encryptdata(data))

              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], Encryptdata(item[1]))

          }
          return formdata
      }
  })
  return SendDta
}